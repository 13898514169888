import Service from "./Service"

const PublicService = {
  getInfoFromActivationCode: params =>
    Service.get(`/public/getInfoFromActivationCode`, { params }),

  getResetPinWidget: data => Service.post(`/public/getResetPinWidget`, data),

  getProgramConfig: programId =>
    Service.get(`/public/programConfig`, {
      params: { programId: programId },
    }),
  getApplicationDetails: applicationId =>
    Service.get(`/public/applicationDetails`, {
      params: { applicationId: applicationId },
    }),
  login: (accountId, applicationId, secretKey) =>
    Service.get(`/public/getSessionToken`, {
      params: {
        accountId: accountId,
        applicationId: applicationId,
        secretKey: secretKey,
      },
    }),
  onboardingAcknowledgement: data =>
    Service.put(`/public/onboardingAcknowledgement`, data),
  activateCard: data => Service.post(`/public/activateCard`, data),
}

export default PublicService
