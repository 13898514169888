import { EXTERNAL_PATH_ROOT } from "../utils/constants"

const ExternalPaths = Object.freeze({
  CONTEXT_SETTING: `/${EXTERNAL_PATH_ROOT}/CardActivation`,
  CARD_ACTIVE: `/${EXTERNAL_PATH_ROOT}/CardActivation/CardActive`,
  ONBOARDING_IFRAME: `/${EXTERNAL_PATH_ROOT}/onboarding/iframe`,
  CVP: `/${EXTERNAL_PATH_ROOT}/onboarding`,
  LOGIN: `/${EXTERNAL_PATH_ROOT}/login`,
})

export default ExternalPaths
